// extracted by mini-css-extract-plugin
export var blogDetail_wrapper = "blogDetails-module--blogDetail_wrapper--S5JzV";
export var blogDetailsBannerOverlay = "blogDetails-module--blogDetailsBannerOverlay--vH7sE";
export var blog_details_author = "blogDetails-module--blog_details_author--TPbaO";
export var blog_details_author_date = "blogDetails-module--blog_details_author_date--u-MHr";
export var blog_details_author_info = "blogDetails-module--blog_details_author_info--9hgPM";
export var blog_details_author_info_inner = "blogDetails-module--blog_details_author_info_inner--ky-dK";
export var blog_details_banner_inner = "blogDetails-module--blog_details_banner_inner--LFxiZ";
export var blog_details_content = "blogDetails-module--blog_details_content--CwA7l";
export var blog_details_img_flex = "blogDetails-module--blog_details_img_flex--2gEDw";
export var blog_details_section = "blogDetails-module--blog_details_section--psolc";
export var blog_details_section_inner = "blogDetails-module--blog_details_section_inner--Y7nvm";
export var blog_details_section_inner_header = "blogDetails-module--blog_details_section_inner_header--trndW";
export var blog_details_section_showcase_inner = "blogDetails-module--blog_details_section_showcase_inner--vReTa";
export var blog_details_section_showcase_wrapper = "blogDetails-module--blog_details_section_showcase_wrapper--UmRim";
export var blog_showcase_carousel = "blogDetails-module--blog_showcase_carousel--EL0h-";
export var blog_showcase_carousel_image = "blogDetails-module--blog_showcase_carousel_image--ZAFRS";
export var blog_showcase_carousel_image_content = "blogDetails-module--blog_showcase_carousel_image_content--iEAhm";
export var blog_showcase_carousel_wrapper = "blogDetails-module--blog_showcase_carousel_wrapper--XvDhQ";
export var blog_showcase_inner_carousel = "blogDetails-module--blog_showcase_inner_carousel--jWzqz";
export var blog_showcase_link = "blogDetails-module--blog_showcase_link--4UamN";
export var overlayleft = "blogDetails-module--overlayleft--MgVmf";
export var overlaymiddle = "blogDetails-module--overlaymiddle--klSW0";
export var overlayright = "blogDetails-module--overlayright--Ccm9B";