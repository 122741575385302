import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { motion } from 'framer-motion';
import * as styles from '../Blog/styles/blogDetails.module.css';
import Layout from '../components/Layout';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';

export default function BlogDetails({ data }) {
  const {
    id,
    Date,
    author,
    authorimg,
    blog_bn,
    blog_header,
    blog_paragraph1,
    blog_paragraph2,
    blog_paragraph3,
    blog_paragraph4,
    blog_paragraph5,
    blog_paragraph6,
    blog_paragraph7,
    blog_paragraph8,
    blog_paragraph9,
    blog_paragraph10,
    slug,
    subTopic,
    sub_header,
    sub_header2,
    mini_header,
    mini_header2,
  } = data.blogDummyJson;

  const blogs = data.allBlogDummyJson.nodes;

  const tl = gsap.timeline();
  gsap.registerPlugin(ScrollTrigger);

  const [width, setWidth] = useState(0);
  const carousel = useRef(null);

  let blogDetailBanner = useRef(null);

  let bannerOverlay1 = useRef(null);
  let bannerOverlay2 = useRef(null);
  let bannerOverlay3 = useRef(null);

  let blogDetailSection = useRef(null);

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - 1450); //990
  }, []);

  // useEffect(() => {
  //   tl.to(bannerOverlay1, 1, {
  //     height: 0,
  //     ease: 'expo.Out',
  //   })
  //   .to(bannerOverlay2, 1, {
  //     height: 0,
  //     ease: 'expo.Out',
  //   })
  //   .to(bannerOverlay3, 1, {
  //     height: 0,
  //     ease: 'expo.Out',
  //   })
  //   .from(blogDetailSection, 1, {
  //     y: 100,
  //     opacity: 0,
  //     ease: 'power3.easeOut',
  //     delay: 0.3,
  //     stagger: {
  //       amount: 0.4,
  //     },
  //     scrollTrigger: {
  //       trigger: blogDetailBanner,
  //       start: '50%',
  //       end: '100%',
  //       pinSpacing: false,
  //       scrub: true,
  //     },
  //   });
  // }, []);

  return (
    <Layout>
      <div className={styles.blogDetail_wrapper}>
        <div
          ref={(el) => {
            blogDetailBanner = el;
          }}>
          <div className={styles.blog_details_banner_inner}>
            <img src={blog_bn} alt='' />
          </div>
          <div className={styles.blogDetailsBannerOverlay}>
            <div
              className={styles.overlayleft}
              ref={(el) => {
                bannerOverlay1 = el;
              }}></div>
            <div
              className={styles.overlaymiddle}
              ref={(el) => {
                bannerOverlay2 = el;
              }}></div>
            <div
              className={styles.overlayright}
              ref={(el) => {
                bannerOverlay3 = el;
              }}></div>
          </div>
        </div>
        <div className={styles.blog_details_section}>
          <div
            className={styles.blog_details_section_inner}
            ref={(el) => {
              blogDetailSection = el;
            }}>
            <h5 className={styles.blog_details_section_inner_header}>
              Introduction to brand building through social media
            </h5>

            <div className={styles.blog_details_author_info}>
              <img src={authorimg} alt='' />
              <div className={styles.blog_details_author_info_inner}>
                <p className={styles.blog_details_author}>{author}</p>
                <p className={styles.blog_details_author_date}>{Date}</p>
              </div>
            </div>
            <hr />
            <div className={styles.blog_details_content}>
              <p>{blog_paragraph1}</p>
              <p>{blog_paragraph2}</p>
              <p>{blog_paragraph3}</p>
              <p>{blog_paragraph4}</p>

              <img src='/blg_content1.png' alt='' />

              <p>{blog_paragraph5}</p>
              <p>{blog_paragraph6}</p>

              <h4>{sub_header}</h4>
              <h5>{mini_header}</h5>
              <img src='/blg_content2.png' alt='' />

              <p>{blog_paragraph7}</p>
              <p>{blog_paragraph8}</p>

              <h4>{sub_header2}</h4>
              <h5>{mini_header2}</h5>

              <div className={styles.blog_details_img_flex}>
                <div>
                  <img src='/blg_content3.png' alt='' />
                </div>
                <div>
                  <img src='/blg_content4.png' alt='' />
                </div>
              </div>

              <p>{blog_paragraph9}</p>
              <p>{blog_paragraph10}</p>
            </div>
          </div>

          <div className={styles.blog_details_section_showcase_wrapper}>
            <div className={styles.blog_details_section_showcase_inner}>
              <h5>Related articles</h5>
              <Link to='/Blog/blogPost'>See all</Link>
            </div>

            <div key={id} className={styles.blog_showcase_carousel_wrapper}>
              <motion.div
                className={styles.blog_showcase_carousel}
                ref={carousel}>
                <motion.div
                  drag='x'
                  dragConstraints={{ right: 0, left: -width }}
                  className={styles.blog_showcase_inner_carousel}>
                  {blogs.map((data) => {
                    return (
                      <motion.div>
                        <div className={styles.blog_showcase_carousel_image}>
                          <img src={data.img} alt='images' />
                        </div>
                        <div
                          className={
                            styles.blog_showcase_carousel_image_content
                          }>
                          <span>{data.subTopic}</span>
                          <h6>{data.topic}</h6>
                          <Link
                            to={'/Blog/blogPost/' + data.slug}
                            className={styles.blog_showcase_link}>
                            View post
                          </Link>
                        </div>
                      </motion.div>
                    );
                  })}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query blogDetails($slug: String) {
    blogDummyJson(slug: { eq: $slug }) {
      Date
      author
      authorimg
      blog_bn
      blog_header
      blog_paragraph1
      blog_paragraph2
      blog_paragraph3
      blog_paragraph4
      blog_paragraph5
      blog_paragraph6
      slug
      subTopic
      sub_header
      sub_header2
      mini_header
      mini_header2
      blog_paragraph7
      blog_paragraph8
      blog_paragraph9
      blog_paragraph10
      id
    }
    allBlogDummyJson {
      nodes {
        subTopic
        topic
        img
        id
        slug
      }
    }
  }
`;
